import { ConfirmationModal, Typography } from "best-common-react";
import React, { createContext, useContext, useState } from "react";

export type GlobalCancelConfirmationType = {
  confirmCancelFn: () => void;
  confirmFn?: () => void;
  confirmationHeader?: string;
  confirmationBody?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

type GlobalContextType = {
  cancel: (cancelConfirmation: GlobalCancelConfirmationType) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  showCancelModal: boolean;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

const GlobalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [confirmCancelFn, setConfirmCancelFn] = useState<() => void>(null);
  const [confirmationHeader, setConfirmationHeader] = useState<string>("Warning");
  const [confirmationBody, setConfirmationBody] = useState<string>(
    "Are you sure you want to cancel?  You will lose any unsaved changes.",
  );
  const [confirmButtonText, setConfirmButtonText] = useState<string>("Yes");
  const [cancelButtonText, setCancelButtonText] = useState<string>("No");
  //RouteLeavingGuard state
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const cancel = (cancelConfirmation: GlobalCancelConfirmationType) => {
    setShowCancelModal(true);
    setConfirmCancelFn(cancelConfirmation.confirmCancelFn);
    setConfirmationHeader(
      cancelConfirmation?.confirmationHeader ? cancelConfirmation?.confirmationHeader : confirmationHeader,
    );
    setConfirmationBody(cancelConfirmation.confirmationBody ? cancelConfirmation.confirmationBody : confirmationBody);
    setConfirmButtonText(
      cancelConfirmation.confirmButtonText ? cancelConfirmation.confirmButtonText : confirmButtonText,
    );
    setCancelButtonText(cancelConfirmation.cancelButtonText ? cancelConfirmation.cancelButtonText : cancelButtonText);
  };

  const confirmCancel = () => {
    setShowCancelModal(false);
    if (confirmCancelFn) {
      confirmCancelFn();
    }
  };

  const undoCancel = () => {
    setShowCancelModal(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        cancel,
        isDirty,
        setIsDirty,
        showCancelModal,
      }}
    >
      {children}

      <ConfirmationModal
        show={showCancelModal}
        header={confirmationHeader}
        headerClose={true}
        body={<Typography variant="bodyMedium">{confirmationBody}</Typography>}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        onCancel={undoCancel}
        onConfirm={confirmCancel}
      />
    </GlobalContext.Provider>
  );
};

const useGlobal = (): GlobalContextType => {
  const globalContext = useContext<GlobalContextType>(GlobalContext);
  if (globalContext === undefined) {
    throw new Error(`useGlobal must be used within a GlobalProvider`);
  }
  return globalContext;
};

export { useGlobal, GlobalProvider };
